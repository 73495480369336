.dropzone {
  display: flex;
  transition: border .2s ease-in-out, background-color .2s ease-in-out;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed #ccc;
  border-radius: .5rem;
  width: 600px;
  height: 200px;
  max-width: 90vw;
  max-height: 90vh;
}

.dropImageText {
  font-weight: 700;
  font-size: 1.05rem;
  
}

.dropzone.noBorder {
  border: none;
}

.dropzone.dragOver {
  border: 2px dashed #60b2fc;
  background-color: #E9F4FE;
  opacity: 1;
}

.hidden {
  display: none;
}
