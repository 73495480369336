.container {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app {
  margin-top: auto;
  margin-bottom: auto;
  display: grid;
  grid-template-rows: auto auto;
  grid-gap: 40px;
  width: 600px;
  justify-content: center;
}

.predictionResult {
}

.predictionResultItem {
  display: flex;
}

.predictionResultName {
  font-weight: 700;
}

.uploadImage {
  max-width: 200px;
  max-height: 180px;
  border-radius: .5rem;
}

.result {
  max-width: 90vw;
  height: 200px;
  opacity: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #eee;
  border-radius: .5rem;
  align-items: center;
  justify-items: center;
  transition: opacity 1s ease-in-out;
}

.result.visible {
  opacity: 1;
}
